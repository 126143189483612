export type IPermissionScreen =
	| 'Businesses'
	| 'Activities'
	| 'Revenue'
	| 'API_Dashboard'
	| 'Roles_Permissions'
	| 'User_List'
	| 'Session_List'
	| 'Analytics_Transactions'
	| 'Billing_Details'
	| 'sub_accounts';

export const PERMISSION_SCREEN: { [key in IPermissionScreen]: string } = {
	Businesses: 'Businesses',
	Activities: 'Activities',
	Revenue: 'Revenue',
	API_Dashboard: 'API Dashboard',
	Roles_Permissions: 'Roles & permissions',
	User_List: 'User List',
	Session_List: 'Session List',
	Analytics_Transactions: 'Analytics & Transactions',
	Billing_Details: 'Billing Details',
	sub_accounts: 'Sub Accounts',
};

//This is for to check the user permission
export const CONDITION_SIDEBAR_TAB = {
	Businesses: 'businesses',
	Activities: 'auditLog',
	Revenue: 'revenue',
	'API Dashboard': 'apiDashboard',
	'Roles & permissions': 'userRoles',
};
