import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { RoleAndPermissionNavigation } from 'views/role-permission/constants';
import { useNewRoles } from 'views/role-permission/hooks';
import {
	PermissionNavigation,
} from '../../store';
import { AddRoleModalHeader } from '../add-role-modal-header';
import { AllowPermission } from '../allow-permission';
import { CreateNewRole } from '../create-new-role';
import { NoRoleAvailable } from '../no-role-available';
import { UserInputRole } from '../user-input-role';
import './add-new-role-modal.scss';

type IAddNewRoleModal = {
	handleCloseModel: () => void;
	isAddRoleModalOpen: boolean;
	children?: ReactNode;
};
export const AddNewRoleModal = ({
	handleCloseModel,
	isAddRoleModalOpen,
	children,
}: IAddNewRoleModal) => {
	//global state
	const navigate = useRecoilValue(PermissionNavigation);

	// hooks
	const {
		mapToNavigationPreviousLabel,
		mapToNavigationNextLabel,
		handleBack,
		handleNext,
		createRoleLoading,
	} = useNewRoles({ handleCloseModel });

	// render modal body
	const mapToNavigationBody: Record<string, ReactNode> = useMemo(
		() => ({
			[RoleAndPermissionNavigation.AddNewRole]: (
				<>
					<UserInputRole />
					<AllowPermission />
				</>
			),
			[RoleAndPermissionNavigation.CreateNewRole]: <CreateNewRole />,
			[RoleAndPermissionNavigation.NoRoleAvailable]: <NoRoleAvailable />,
			[RoleAndPermissionNavigation.EditRole]: (
				<>
					<UserInputRole />
					<AllowPermission />
				</>
			),
		}),
		[]
	);

	// render modal header
	const mapToNavigationHeader: Record<string, JSX.Element> = useMemo(
		() => ({
			[RoleAndPermissionNavigation.AddNewRole]: (
				<AddRoleModalHeader navigate={navigate} />
			),
			[RoleAndPermissionNavigation.CreateNewRole]: (
				<AddRoleModalHeader navigate={navigate} />
			),
			[RoleAndPermissionNavigation.NoRoleAvailable]: (
				<AddRoleModalHeader navigate={navigate} />
			),
			[RoleAndPermissionNavigation.EditRole]: (
				<AddRoleModalHeader navigate={navigate} />
			),
		}),
		[navigate]
	);

	const renderBody = useMemo(
		() => mapToNavigationBody[navigate],
		[mapToNavigationBody, navigate]
	);

	const renderTitle = useMemo(
		() => mapToNavigationHeader[navigate],
		[mapToNavigationHeader, navigate]
	);


	return (
		<div className="Add-role-modal--container">
			<Modal
				isOpen={isAddRoleModalOpen}
				closeModal={handleCloseModel}
				modalName="Add user role"
				className="AddNewRoleModal"
				title={renderTitle}
				optionalClassName="AddNewRoleModal"
			>
				<div className="AddNewRoleModal--container">{renderBody}</div>
				{children ? (
					children
				) : (
					<div className="AddNewRoleModal--container__footer">
						<Button
							label={mapToNavigationPreviousLabel}
							type="button__filled--secondary"
							handleClick={handleBack}
						/>
						<Button
							label={
								createRoleLoading ? (
									<Loader dimension={22} className="loader-white" />
								) : (
									mapToNavigationNextLabel
								)
							}
							type="button__filled--primary"
							handleClick={handleNext}
							disabled={ createRoleLoading}
						/>
					</div>
				)}
			</Modal>
		</div>
	);
};
