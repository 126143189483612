import { Fragment, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import Modal from '@storybook/new-modal/modal';
import { ReactResponsiveTable } from '@storybook';

import {
	SubAccountHeaders,
	SubAccountUsersHeaders,
	getStatusClassName,
	subAccountDefaultRows,
} from './constants';
import { getDate } from 'utils';
import { SelectedSubAccountState } from './stores/states';

import './sub-account-list.scss';

export const SubAccountsList = () => {
	const [open, setOpen] = useState(false);
	const [selectedSubAccount, setSelectedSubAccount] = useRecoilState(
		SelectedSubAccountState
	);

	const handleOnClickViewUser = (item: any) => {
		setSelectedSubAccount(item);
		setOpen(true);
	};
	const renderTransactions = useMemo(() => {
		const rows: any = [];
		subAccountDefaultRows?.forEach((item) => {
			if (item) {
				let row: any = {};
				SubAccountHeaders.forEach(({ key }) => {
					if (key === 'totalUsers') {
						const value = () => (
							<div className="sub_account_users_count">
								<div>{item[key]}</div>
								<div
									className="sub_account_users_count__view_btn"
									onClick={() => handleOnClickViewUser(item)}
								>
									view
								</div>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'createdAt') {
						const value = () => (
							<div className="row_content_wrapper">
								<div className="row_content_wrapper__title">
									{getDate(item[key])}
								</div>
							</div>
						);
						return (row[key] = value);
					}

					row = { ...row, [key]: item[key] ?? '--' };
				});
				rows.push(row);
			}
		});

		return rows ?? [];
	}, [subAccountDefaultRows]);

	const renderTransactionsUser = useMemo(() => {
		const rows: any = [];
		selectedSubAccount.users?.forEach((item) => {
			if (item) {
				let row: any = {};
				row.name = item.firstName + ' ' + item.lastName;
				SubAccountUsersHeaders.forEach(({ key }) => {
					if (key === 'name') {
						return (row[key] = `${item.firstName} ${item.lastName}`);
					}
					if (key === 'subscription') {
						const value = () => (
							<div className="sub-account-user-subscription">
								<div className="sub-account-user-subscription__value">
									{item[key].service ?? 'N/A'}
								</div>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'createdAt') {
						const value = () => (
							<div className="row_content_wrapper">
								<div className="row_content_wrapper__title">
									{getDate(item[key])}
								</div>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'status') {
						const value = () => (
							<div className="row_content_wrapper">
								<div
									className={`row_content_wrapper__title ${getStatusClassName[item[key]] ?? ''}`}
								>
									{item[key] ?? 'N/A'}
								</div>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'actions') {
						const value = () => (
							<div className="row_content_wrapper">
								<div className="row_content_wrapper__title">
									<i
										style={{ fontSize: 16, fontWeight: 600 }}
										className="ri-more-2-fill"
									></i>
								</div>
							</div>
						);
						return (row[key] = value);
					}

					row = { ...row, [key]: item[key] ?? '--' };
				});
				rows.push(row);
			}
		});

		return rows ?? [];
	}, [selectedSubAccount.users]);
	return (
		<Fragment>
			<div className="sub-account-table-wrapper">
				<ReactResponsiveTable
					column={SubAccountHeaders}
					rows={renderTransactions ?? []}
				/>
			</div>

			<Modal
				isOpen={open}
				modalName={'users-modal'}
				className="sub_account-user-modal"
				closeModal={() => setOpen(false)}
				title={
					<div style={{ height: 40, borderBottom: '1px solid #0000001F' }}>
						<div style={{ fontWeight: 600, fontSize: 20, color: '#1D1E21' }}>
							Users
						</div>
					</div>
				}
			>
				<ReactResponsiveTable
					column={SubAccountUsersHeaders}
					rows={renderTransactionsUser ?? []}
				/>
			</Modal>
		</Fragment>
	);
};
