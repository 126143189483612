import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
	ReactResponsiveTable,
	Loader,
	Button,
	Input,
	ReactDropdown,
	DatePicker,
} from '@storybook';

import { useNotification, useNetwork } from 'hooks';
import { API_URL, message, searchDropdownOptions } from 'constant';
import {
	SUPER_ADMIN_TABLE,
	SUPER_ADMIN_VIEWLIST_MODAL_HEADER,
	HEADER_ACTIVE,
	CUSTOM_HEADER_ACTIVE,
	ApproveStatus,
} from './constants';
import {
	BsuinessDetailsModal,
	Pagination,
	BusinessUserList,
	BusinessSessionList,
	AnalyticsTransactions,
	BillingDetails,
	VerificationTimeInterval,
	ApprovalModal,
	OpenApprovalModalState,
	BrowseFile,
	UploadedFiles,
	SubAccountsList,
} from './components';
import {
	Header,
	PERMISSION_SCREEN,
	TransactionDetailListState,
	TransactionsAnalyticsChartState,
	TransactionsRevenueChartState,
	TransactionsTotalSummaryState,
	useDashboard,
} from 'views';
import {
	convertStringToNumber,
	getCurrentEvrionment,
	getDate,
	getTime,
} from 'utils';

import { useDropOption, useFetchBusinessList, useLockUnlock } from './hooks';
import { ICompanyList, ICompanyRows, ICompanySelected } from './types';
import {
	BillingTableErrorState,
	BillingTableDataState,
	UploadedEnvelopeDocsState,
	InboxfilteringTagsState,
} from './states';
import { DropOption } from 'components';
import Modal from '@storybook/new-modal/modal';
import { SelectedDateRangeState } from 'states';
import { BusinessFilter } from './components/businessFilter';
import { envHost } from 'helpers';

const isStage = envHost === 'stage';
const isPP = envHost === 'pp';

export const Business = () => {
	//local state
	const [businessModalActiveHeader, setBusinessModalActiveHeader] = useState(
		HEADER_ACTIVE.USERS
	);
	const [{ grid }, setGrid] = useRecoilState(BillingTableDataState);
	const [openBusinessModalView, setOpenBusinessModalView] =
		useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<null | ICompanySelected>(
		null
	);
	const [openApproveRejectModal, setOpenApproveRejectModal] = useRecoilState(
		OpenApprovalModalState
	);
	const [filteringTags] = useRecoilState(InboxfilteringTagsState);
	const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
	const [pageCount, setPageCount] = useState<number>(0);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [searchType, setSearchType] = useState(searchDropdownOptions[0].value);
	const isBillingTableError = useRecoilValue(BillingTableErrorState);
	const resetTransactionsTotal = useResetRecoilState(
		TransactionsRevenueChartState
	);
	const resetTransactionsAnalytics = useResetRecoilState(
		TransactionsAnalyticsChartState
	);
	const resetTransactionsTotalSummary = useResetRecoilState(
		TransactionsTotalSummaryState
	);
	const resetTransactionDetailList = useResetRecoilState(
		TransactionDetailListState
	);

	const [selectedDateRange, setSelectedDateRange] = useRecoilState(
		SelectedDateRangeState
	);

	// helper
	const { errorNotification, successNotification } = useNotification();

	// hooks
	const {
		SuperAdminData,
		setsuperAdminData,
		SuperAdminError,
		SuperAdminLoading,
		totalPages,
	} = useFetchBusinessList(
		currentPageNumber,
		searchTerm,
		setCurrentPageNumber,
		searchType,
		selectedDateRange,
		filteringTags?.actionStatus
	);
	const { OptionList, isOpen, setIsOpen } = useDropOption();
	const { patch } = useNetwork();
	const handleCloseModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	const { post: postBusinessDetails, loading } = useNetwork();

	const [loadingButton, setLoadingButton] = useState(null);
	const {
		handleOnClickLockUnlock,
		isLockLoading,
		lockSelectedItem,
		lockunlockSuccessData,
		isLockUnlockStatus,
	} = useLockUnlock();
	const { checkUserWritePermission, customSubModuleTab } = useDashboard();
	const currentEnvironment = getCurrentEvrionment();
	const businessSubModuleList = useMemo(
		() => customSubModuleTab(PERMISSION_SCREEN.Businesses),
		[customSubModuleTab]
	);

	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);

	useEffect(() => {
		setBusinessModalActiveHeader(
			CUSTOM_HEADER_ACTIVE[businessSubModuleList?.[0]]
		);
	}, [businessSubModuleList]);

	useEffect(() => {
		setPageCount(totalPages);
	}, [totalPages]);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(false, PERMISSION_SCREEN.Businesses),
		[checkUserWritePermission]
	);

	const isUserBillingPermissionWrite = useMemo(
		() =>
			checkUserWritePermission(
				true,
				PERMISSION_SCREEN.Businesses,
				PERMISSION_SCREEN.Billing_Details
			),
		[checkUserWritePermission]
	);

	const redirectToLinekdin = (
		e: React.MouseEvent<HTMLElement>,
		url: string
	) => {
		e.stopPropagation();
		if (url) {
			window.open(url, '_blank');
		}
	};

	const handleOpenApproveReject = useCallback((event, item) => {
		event.stopPropagation();
		setSelectedItem(item);
		setOpenApproveRejectModal(true);
	}, []);

	const renderBusinessListTable = useMemo(() => {
		const rows = [];
		SuperAdminData?.forEach((compnyList: ICompanyList) => {
			if (compnyList) {
				let row: ICompanyRows = {
					_id: '',
					id: '',
					name: '',
					company_name: '',
					company_address: '',
					user_email: '',
					user_name: '',
					linkedinUrl: '',
					kyb: '',
					createdAt: '',
					currentStatus: '',
					physicalVerificationStatus: '',
					nodes: {
						authentication: '',
						kyc: '',
						kyb: '',
					},
					accountType: '',
				};
				SUPER_ADMIN_TABLE.HEADER.forEach(({ format, key, key2 }) => {
					const {
						companyName,
						_id,
						users,
						address,
						approveStatus,
						linkedInUrl,
						createdAt,
						kyb,
						isLock,
						physicalVerificationStatus,
						nodes,
						accountType,
					} = compnyList ?? {};
					const { firstName, lastName, email } = users ?? {};
					row.id = _id ?? '';
					row.name = companyName ?? '--';
					row.company_name = companyName ?? '--';
					row.company_address = address ?? '--';
					row.user_email = email ?? '--';
					row.user_name = `${firstName ?? '--'} ${lastName ?? '--'}`;
					row.linkedinUrl = linkedInUrl ?? '--';
					row.kyb = kyb ?? '--';
					row.createdAt = createdAt ?? '--';
					row.currentStatus = approveStatus ?? '--';
					row.physicalVerificationStatus = physicalVerificationStatus ?? '--';
					row.accountType = accountType ?? '--';
					nodes.forEach((item) => {
						row.nodes[item.stepId] = item.status;
					});
					if (key === 'users') {
						const value = () => {
							const { firstName, lastName, email } = compnyList[key] ?? {};

							return (
								<div>
									<div className="row_content_wrapper">
										<div className="row_content_wrapper__title">
											{`${firstName ?? '--'} ${lastName ?? '--'}`}
										</div>
										<div className="row_content_wrapper__sub_title">
											{email}
										</div>
									</div>
								</div>
							);
						};
						return (row[key] = value);
					}
					if (key2 === 'address') {
						const value = () => (
							<div className="company-brand-logo-wrapper--container">
								<div className="company-brand-logo-wrapper">
									{compnyList?.logo ? (
										<a
											onClick={(e) => redirectToLinekdin(e, linkedInUrl)}
											rel="noreferrer"
											className="company-brand-logo-anchor"
										>
											<img
												src={compnyList.logo}
												alt="company_logo"
												className="brand-logo"
											/>
										</a>
									) : (
										<a
											onClick={(e) => redirectToLinekdin(e, linkedInUrl)}
											rel="noreferrer"
											className="company-brand-logo-anchor"
										>
											<i className="ri-building-line set-icon default-company-logo" />
										</a>
									)}
								</div>
								<div className="row_content_wrapper">
									<div className="row_content_wrapper__title">
										{compnyList[key] ?? '--'}
									</div>
									<div className="row_content_wrapper__sub_title">
										{compnyList[key2]?.address1 ?? '--'}
									</div>
								</div>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'approveStatus') {
						const { classes, label } = ApproveStatus(compnyList[key] ?? '');
						const value = () => (
							<div className="status-wrapper">
								<span className={classes} />
								<span>{label ?? '--'}</span>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'physicalVerification') {
						//Deepak : As per discuss with janvi currently this is not implemented as aspected so hiding this as of now and adding for physical verification
						// const value = () => (
						// 	<div className="business-session-status">
						// 		<span className={`tag tag__${row.nodes.authentication}`}>
						// 			Biometric
						// 		</span>
						// 		<img src={connectorline} alt={'connectorline'}></img>
						// 		<span className={`tag tag__${row.nodes.kyc}`}>KYC</span>
						// 		<img src={connectorline} alt={'connectorline'}></img>
						// 		<span className={`tag tag__${row.nodes.kyb}`}>KYB</span>
						// 	</div>
						// );
						// return (row[key] = value);
						const value = () => (
							<div className="status-wrapper">
								<span
									className={
										compnyList[
											'physicalVerificationStatus'
										]?.toLowerCase?.() === 'request processed'
											? 'requested'
											: compnyList[
													'physicalVerificationStatus'
												]?.toLowerCase?.()
									}
								/>
								<span>
									{compnyList['physicalVerificationStatus']?.toLowerCase() ===
									'null'
										? '--'
										: compnyList[
													'physicalVerificationStatus'
											  ]?.toLowerCase() === undefined
											? '--'
											: compnyList['physicalVerificationStatus'] === 'PENDING'
												? 'Requested'
												: compnyList[
														'physicalVerificationStatus'
													]?.toLowerCase()}
								</span>
							</div>
						);
						return (row[key] = value);
					}

					if (key2 === 'createdAt') {
						const value = () => (
							<div className="row_content_wrapper">
								<div className="row_content_wrapper__title">
									{getDate(compnyList[key2])}
								</div>
								<div className="row_content_wrapper__sub_title">
									{getTime(compnyList[key2])}
								</div>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'action') {
						const value = () => (
							<div className="action-icon-wrapper--container">
								{isUserPermissionWrite && (
									<div
										onClick={(event) =>
											handleOnClickLockUnlock(event, compnyList)
										}
										className="action-icon-wrapper"
									>
										{lockSelectedItem?._id === _id && isLockLoading ? (
											<Loader className="loader-white" dimension={20} />
										) : (
											<i
												className={
													isLock ? 'ri-lock-line' : 'ri-lock-unlock-line'
												}
											/>
										)}
									</div>
								)}
								<div
									onClick={(event) =>
										handleOpenApproveReject(event, compnyList)
									}
									className="action-icon-wrapper"
								>
									<i className="ri-settings-2-line"></i>
								</div>
								{(isStage || isPP) && (
									<DropOption
										options={OptionList(compnyList, handleOnClickLockUnlock)}
									/>
								)}
							</div>
						);
						return (row[key] = value);
					}
					if (format === 'jsx') {
						const value = () => (
							<>
								{key === 'invested' ? `$${compnyList[key]}` : compnyList[key]}
							</>
						);
						return (row[key] = value);
					}

					row = { ...row, [key]: compnyList[key] };
				});
				rows.push(row);
			}
		});

		return rows ?? [];
	}, [isUserPermissionWrite, SuperAdminData]);

	useEffect(() => {
		if (lockunlockSuccessData) {
			if (isLockUnlockStatus) {
				setsuperAdminData(
					SuperAdminData?.map((items: ICompanyList) => {
						if (items._id === lockSelectedItem?._id) {
							return { ...items, isLock: lockunlockSuccessData.isLock };
						} else {
							return items;
						}
					})
				);
			}
		}
	}, [lockunlockSuccessData]);

	const handleView = useCallback((item) => {
		if (!businessSubModuleList.length) return;
		setSelectedItem(item);
		setOpenBusinessModalView(true);
	}, []);

	const handleCloseViewModal = useCallback(() => {
		setGrid({});
		setOpenBusinessModalView(false);
		setBusinessModalActiveHeader(
			CUSTOM_HEADER_ACTIVE[businessSubModuleList?.[0]]
		);
		resetTransactionsTotal();
		resetTransactionsAnalytics();
		resetTransactionsTotalSummary();
		resetTransactionDetailList();
	}, []);

	const renderSuperAdminTable = useMemo(() => {
		return (
			<ReactResponsiveTable
				isLoading={SuperAdminLoading}
				numberOfList={8}
				error={SuperAdminError}
				column={SUPER_ADMIN_TABLE.HEADER}
				rows={renderBusinessListTable}
				showSearch={false}
				showFilter={false}
				columnHandle={true}
				height="calc(100vh - 205px)"
				emptyHeight={'calc(100vh - 255px)'}
				handelRowClick={(item) => handleView(item)}
				showSorting
			/>
		);
	}, [renderBusinessListTable, SuperAdminLoading]);

	const renderActiveTabs = useMemo(() => {
		if (
			currentEnvironment === 'stage' ||
			currentEnvironment === 'pp' ||
			currentEnvironment === 'prod'
		) {
			return SUPER_ADMIN_VIEWLIST_MODAL_HEADER;
		} else {
			return SUPER_ADMIN_VIEWLIST_MODAL_HEADER.filter(
				(items) => items.ACTIVE !== HEADER_ACTIVE.TRANSACTIONS
			);
		}
	}, [businessModalActiveHeader, selectedItem, openBusinessModalView]);

	const renderBusinessModalTitle = useMemo(() => {
		return (
			<div className="tab-navigation">
				{renderActiveTabs.map((item) => {
					return (
						businessSubModuleList.includes(item.TITLE) && (
							<div
								key={item.KEY + '_' + item.TITLE}
								tabIndex={item.TAB}
								className={
									businessModalActiveHeader === item.ACTIVE
										? 'active'
										: 'un-active'
								}
								onClick={() => setBusinessModalActiveHeader(item.ACTIVE)}
							>
								{item.TITLE}
							</div>
						)
					);
				})}
			</div>
		);
	}, [businessModalActiveHeader, selectedItem, openBusinessModalView]);

	const renderBusinessModalBody = () => {
		if (!businessModalActiveHeader && !SuperAdminData) return;
		const selectedBusiness = SuperAdminData?.filter(
			({ _id }) => _id === selectedItem?.id
		);
		if (businessModalActiveHeader === HEADER_ACTIVE.USERS) {
			return <BusinessUserList data={selectedBusiness} />;
		} else if (businessModalActiveHeader === HEADER_ACTIVE.SESSIONS) {
			return <BusinessSessionList data={selectedBusiness} />;
		} else if (businessModalActiveHeader === HEADER_ACTIVE.SUB_ACCOUNTS) {
			return <SubAccountsList />;
		} else if (businessModalActiveHeader === HEADER_ACTIVE.BILLINGS) {
			return <BillingDetails item={selectedItem} />;
		} else {
			return (
				<AnalyticsTransactions currentBusinessId={selectedBusiness[0]?._id} />
			);
		}
	};

	const isShow = useMemo(
		() => businessModalActiveHeader === HEADER_ACTIVE.BILLINGS,
		[businessModalActiveHeader]
	);

	const handleSubmit = () => {
		if (isBillingTableError) {
			return errorNotification('Only numeric is allowed');
		}

		if (businessModalActiveHeader === HEADER_ACTIVE.BILLINGS) {
			const Obj: any = {};
			for (const element of grid) {
				let label;
				const pricesObj = {
					domestic: 0,
					international: 0,
					monthly: 0,
					ourCost: 0,
				};
				for (let j = 0; j < element?.length; j++) {
					const {
						keys: key,
						value,
						stepId,
						price,
						service,
					} = element?.[j] ?? {};
					// eslint-disable-next-line no-prototype-builtins
					if (pricesObj.hasOwnProperty(price)) {
						pricesObj[price] = convertStringToNumber(value);
					}
					if (price === 'label') {
						label = value;
					}
					if (key) {
						Obj[key] = {
							label: label,
							service,
							stepId: stepId,
							price: { ...pricesObj },
						};
					}
				}
			}

			postBusinessDetails(`${API_URL.BUSINESS_DETAILS}${selectedItem.id}`, {
				...Obj,
				businessId: selectedItem.id,
			})
				.then((res: any) => {
					if (res.data) {
						successNotification(message.UPDATE_SUCCESS_MESSAGE);
					} else {
						errorNotification(
							res?.message ?? message.SomethingWentWrongMessage
						);
					}
				})

				.catch((err: any) => {
					errorNotification(err.message ?? message.SomethingWentWrongMessage);
				});
		}
	};

	const renderBusinessModalCloseButton = () => {
		return (
			<div
				className="client-details-modal__footer"
				style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
			>
				<button
					className="button button__filled button__filled--secondary "
					onClick={handleCloseViewModal}
				>
					Close
				</button>

				{isUserBillingPermissionWrite && isShow && (
					<Button
						type="button button__filled--primary"
						label={
							loading ? (
								<Loader className="loader-white" dimension={20} />
							) : (
								'Save'
							)
						}
						handleClick={handleSubmit}
					/>
				)}
			</div>
		);
	};

	const renderBusinessDetailsModal = () => {
		return (
			<BsuinessDetailsModal
				isOpen={openBusinessModalView}
				handleCloseViewModal={handleCloseViewModal}
				className="client-details-modal"
				modalName="client details modal"
				ModalTitle={renderBusinessModalTitle}
			>
				<div className="businessDetailsModal--body-wapper">
					{renderBusinessModalBody()}
					{renderBusinessModalCloseButton()}
				</div>
			</BsuinessDetailsModal>
		);
	};

	const handlePrevClick = useCallback(() => {
		setCurrentPageNumber((prevCount) => prevCount - 1);
		setsuperAdminData([]);
	}, [currentPageNumber]);

	const handleNextClick = useCallback(() => {
		setCurrentPageNumber((prevCount) => prevCount + 1);
		setsuperAdminData([]);
	}, [currentPageNumber]);

	const renderPagination = useCallback(() => {
		return (
			<Pagination
				isLoaded={SuperAdminLoading}
				pageNumber={currentPageNumber}
				pageCount={pageCount}
				handlePrevClick={handlePrevClick}
				handleNextClick={handleNextClick}
			/>
		);
	}, [
		SuperAdminLoading,
		setCurrentPageNumber,
		pageCount,
		handlePrevClick,
		handleNextClick,
	]);

	const searchbusiness = useCallback(
		(e) => {
			setSearchTerm(e.target.value);
		},
		[searchTerm]
	);

	const handleChange = (val: any) => {
		setSearchType(val.value);
	};
	const approvePayload = {
		upload: true,
		base64Image: envelopeData?.data?.base64File,
		verificationStatus: 'APPROVED',
	};

	const denyPayload = {
		upload: true,
		base64Image: envelopeData?.data?.base64File,
		verificationStatus: 'REJECTED',
	};

	const handleAPProve = async () => {
		setLoadingButton('approve');
		const response = await patch(
			`${API_URL.BUSINESSES}/${lockSelectedItem?._id}`,
			approvePayload
		);
		setLoadingButton(false);

		setIsOpen(false);

		if (response?.physicalVerificationStatus === 'APPROVED') {
			successNotification('Status Approved');
			setsuperAdminData((prev) => {
				const clonedAdminData = structuredClone(prev);
				const index = clonedAdminData.findIndex(
					(el: any) => el?._id === lockSelectedItem?._id
				);
				if (index !== -1) {
					clonedAdminData[index].physicalVerificationStatus =
						response?.physicalVerificationStatus;
				}
				return clonedAdminData;
			});
		}
	};

	const handleDeny = async () => {
		setLoadingButton('deny');

		const response = await patch(
			`${API_URL.BUSINESSES}/${lockSelectedItem?._id}`,
			denyPayload
		);
		setLoadingButton(false);

		setIsOpen(false);

		if (response?.physicalVerificationStatus === 'REJECTED') {
			successNotification('Status Rejected');
			setsuperAdminData((prev) => {
				const clonedAdminData = structuredClone(prev);
				const index = clonedAdminData.findIndex(
					(el: any) => el?._id === lockSelectedItem?._id
				);
				if (index !== -1) {
					clonedAdminData[index].physicalVerificationStatus =
						response?.physicalVerificationStatus;
				}
				return clonedAdminData;
			});
		}
	};

	const handleOnClickDateFilter = useCallback(() => {
		setsuperAdminData([]);
		setCurrentPageNumber(1);
	}, [selectedDateRange, setSelectedDateRange]);

	const handleResetData = useCallback(() => {
		setsuperAdminData([]);
		setCurrentPageNumber(1);
	}, []);

	return (
		<div className="super-admin--container">
			<Header title="Businesses" />
			<div className="super-admin-container-wrapper">
				<div className="super-admin-search-container">
					<BusinessFilter onChange={handleResetData} />
					<DatePicker onSubmit={handleOnClickDateFilter} />
					<ReactDropdown
						options={searchDropdownOptions}
						defaultValue={searchDropdownOptions[0]}
						handleChangeSelect={handleChange as any}
					/>
					<Input
						label={''}
						placeholder="Search"
						prefixIcon="ri-search-line"
						allowClear={false}
						inputType="text"
						handleChange={(e) => searchbusiness(e)}
						value={searchTerm}
					/>
				</div>
				<div className="super-admin-container">
					{renderSuperAdminTable}
					{renderPagination()}
				</div>
			</div>
			{openBusinessModalView && renderBusinessDetailsModal()}
			{openApproveRejectModal && (
				<ApprovalModal
					selectedItem={selectedItem}
					SuperAdminData={SuperAdminData}
					setsuperAdminData={setsuperAdminData}
				/>
			)}
			<VerificationTimeInterval />
			{isOpen && (
				<Modal
					isOpen={isOpen}
					modalName={''}
					closeModal={handleCloseModal}
					isStopOutsideClick={false}
					showCloseBtn
					className="start-new-modal"
				>
					<div className="headers">
						<div className="header__name">Upload Report</div>
					</div>
					<div className="upload-documents">
						<BrowseFile />
						<UploadedFiles />
					</div>
					<div className="FooterNavigate--container">
						<div className="FooterNavigate--right">
							<Button
								handleClick={handleDeny}
								label={
									loadingButton === 'deny' ? (
										<Loader className="loader-white" dimension={20} />
									) : (
										'Deny'
									)
								}
								type="button__ghost button__filled--danger"
								buttonType="button"
								disabled={envelopeData?.data?.base64File ? false : true}
							/>

							<Button
								handleClick={handleAPProve}
								label={
									loadingButton === 'approve' ? (
										<Loader className="loader-white" dimension={20} />
									) : (
										'Approve'
									)
								}
								// label={'Approve'}
								type="button__filled button__filled--success"
								buttonType="button"
								disabled={envelopeData?.data?.base64File ? false : true}
							/>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};
