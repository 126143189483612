import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { ReactSwitch } from '@storybook';
import alertFill from 'assets/svg/alert-fill.svg';

import {
	AllowPermissionLabel,
	AddNewRoleFormState,
	PermissionTableDataState,
	SelectedUserRoleState,
	useRolepermission,
	IPermissionType,
	IReadWrite,
} from 'views';
import { PermissionTable } from '../permission-table';

interface IAllowPermission {
	isDisable?: boolean;
}

export const AllowPermission: FC<IAllowPermission> = ({
	isDisable = false,
}) => {
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);
	const selectedUserRole = useRecoilValue(SelectedUserRoleState);
	const permissionListTableData = useRecoilValue(PermissionTableDataState);
	const [isToggle, setIsToggle] = useState(false);

	const { getAllPermissionTag } = useRolepermission();
	
	useEffect(() => {
		if (getAllPermissionTag(addRoleForm.permissions as any, 'fullAccess')) {
			setIsToggle(true);
		} else {
			setIsToggle(false);
		}
	}, [addRoleForm.permissions]);

	const isFullAccess = useMemo(() => {
		return selectedUserRole?.permissions?.length
			? getAllPermissionTag(selectedUserRole.permissions, 'fullAccess')
			: false;
	}, [selectedUserRole]);

	const fullAccessValue = useMemo(() => {
		return permissionListTableData.map((item) => ({
			module: item._id,
			subModules: item.subModules.map((subModule) => ({
				subModuleId: subModule._id,
				view: true,
				edit: true,
				create: true,
			})),
			view: true,
			edit: true,
			create: true,
		}));
	}, [permissionListTableData]);

	const handleToggleChange = (checked: boolean) => {
		setIsToggle(checked);

		setAddRoleForm((prev) => ({
			...prev,
			permissions: checked ? fullAccessValue : [],
		}));
	};

	const disabledAddRoleBtn = useMemo(() => {
		const { permissions } = addRoleForm ?? {};
		
		const hasAnyReadOrWritePermission = Object.keys(permissions ?? []).some(
			(category) => {
				return ['view', 'edit'].some(
					(permission) =>
						permissions[category as IPermissionType][permission as IReadWrite]
				);
			}
		);

		if (!hasAnyReadOrWritePermission) {
			setAddRoleForm((prev) => ({
				...prev,
				formErrors: {
					...prev.formErrors,
					permissions: 'Please select at least one permission for this role.',
				},
			}));
		} else {
			setAddRoleForm((prev) => ({
				...prev,
				formErrors: {
					...prev.formErrors,
					permissions: null,
				},
			}));
		}
		return !hasAnyReadOrWritePermission;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addRoleForm.permissions]);

	const toggleActive = useCallback(
		(isAllowedAccess: boolean) => {
			return classNames('AddNewRoleModal--container__permission--access', {
				'toggle-border': isAllowedAccess || (isDisable && isFullAccess),
			});
		},
		[isDisable, isFullAccess]
	);

	const renderPermissionHeader = useMemo(() => {
		return (
			<div className="AddNewRoleModal--container__permission--header">
				<div className="AddNewRoleModal--container__permission--header__title">
					{AllowPermissionLabel.Header}
				</div>
				{!isDisable && (
					<div className="AddNewRoleModal--container__permission--header__subTitle">
						{AllowPermissionLabel.Description}{' '}
						{(addRoleForm?.formErrors?.permissions !== null && addRoleForm?.formErrors?.permissions !== undefined) && (
							<div className="AddNewRoleModal--container__permission--header__error">
								<span className="alert_error">
									<img
										className={'AddNewRoleModal--container__permission--header__error--alertIcon'}
										src={alertFill}
										alt={'alertFill'}
									></img>{' '}
									{addRoleForm?.formErrors?.permissions}
								</span>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}, [isDisable, addRoleForm?.formErrors?.permissions, disabledAddRoleBtn]);

	

	const renderPermissionCard = useMemo(() => {
		return (
			<div className={toggleActive(isToggle)}>
				<div className="All-access--container">
					<div className="All-access--container__header">
						<div className="All-access--container__header--title">
							{AllowPermissionLabel.Card.Header}
						</div>
						<div className="All-access--container__header--subTitle">
							{AllowPermissionLabel.Card.Description}
						</div>
					</div>
					<div className="AddNewRoleModal--container__permission--access__toggle">
						{isDisable ? (
							<i className="ri-check-line permission-checked_full-access" />
						) : (
							<ReactSwitch
								id="allow-access-toggle"
								checked={isToggle}
								handleChange={handleToggleChange}
								isDisabled={isDisable}
							/>
						)}
					</div>
				</div>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isToggle, isDisable]);

	return (
		<div className="AddNewRoleModal--container__permission">
			{renderPermissionHeader}
			{isDisable ? (
				isFullAccess ? (
					renderPermissionCard
				) : (
					<></>
				)
			) : (
				renderPermissionCard
			)}
			<PermissionTable
				isAllowedAccess={isToggle || isDisable}
				isEditable={isDisable}
			/>
		</div>
	);
};
