import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { UserDetailsState } from '../states';

export const useDashboard = () => {
	const [userDetails, setUserDetails] = useRecoilState(UserDetailsState);
	const { get } = useNetwork();

	const getUserDetail = useCallback(async () => {
		const response = await get(API_URL.USER_DETAILS);

		if (response?.data?._id) {
			setUserDetails(response.data);
		}
	}, []);

	const customUserTab: string[] = useMemo(() => {
		const { role } = userDetails?.roles?.[0] ?? {};
		const { permissions } = role ?? {};

		const trueKeys: string[] = [];
		for (const key in permissions) {
			if (
				permissions[key]?.create ||
				permissions[key]?.view ||
				permissions[key]?.edit
			) {
				trueKeys.push(permissions[key]?.module?.name);
			}
		}
		return trueKeys ?? [];
	}, [userDetails]);

	const checkUserWritePermission = useCallback(
		(
			isSubModule: boolean,
			screenName: string | undefined = '',
			subModulesName: string | undefined = ''
		) => {
			const { role } = userDetails?.roles?.[0] ?? {};
			const { permissions } = role ?? {};

			const modulePermission = permissions.find((item) => {
				return item?.module?.name === screenName;
			});
			if (isSubModule) {
				const subModulePermission = modulePermission?.module?.subModules.find(
					(item) => item.name === subModulesName
				);
				return (
					(subModulePermission?.edit || subModulePermission?.create) ?? false
				);
			} else {
				return (modulePermission?.edit || modulePermission?.create) ?? false;
			}
		},
		[userDetails]
	);

	const customSubModuleTab = useCallback(
		(screenName: string) => {
			const { role } = userDetails?.roles?.[0] ?? {};
			const { permissions } = role ?? {};

			const modulePermission = permissions.find((item) => {
				return item?.module?.name === screenName;
			});
			const subModules = JSON.parse(
				JSON.stringify(modulePermission?.module?.subModules)
			);

			const trueKeys: string[] = [];

			subModules.push({
				name: 'Sub Accounts',
				isActive: true,
				_id: '65f18a973fc79e13abef22ce',
				view: true,
				edit: true,
				create: true,
			});

			for (const key in subModules) {
				if (
					subModules[key]?.create ||
					subModules[key]?.view ||
					subModules[key]?.edit
				) {
					trueKeys.push(subModules[key]?.name);
				}
			}
			return trueKeys ?? [];
		},
		[userDetails]
	);

	return {
		getUserDetail,
		customUserTab,
		checkUserWritePermission,
		customSubModuleTab,
	};
};
