export const SubAccountHeaders = [
	{
		label: 'Sub account ',
		key: 'name',
		format: 'string',
		className: '',
		width: '25%',
	},
	{
		label: 'Account',
		key: 'account',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Creation date',
		key: 'createdAt',
		format: 'jsx',
		className: 'business-type',
		width: '12%',
	},
	{
		label: 'Transactions',
		key: 'transactions',
		format: 'string',
		width: '10%',
	},
	{
		label: 'Total revenue',
		key: 'total_revennue',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Users',
		key: 'totalUsers',
		format: 'jsx',
		width: '14%',
	},
];

export const subAccountDefaultRows = [
	{
		_id: '667a83f20ade7765010e00c9',
		name: 'janviii',
		account: 'Deloitte',
		parentBusinessId: '6639d998d709041133de2d0f',
		ownBilling: true,
		transactions: 322,
		total_revennue: '$43323.00',
		differentCompany: false,
		subscription: null,
		approveStatus: 'APPROVED',
		isLock: false,
		isLive: false,
		createdAt: '2024-06-25T08:46:42.742Z',
		updatedAt: '2024-06-25T08:46:42.742Z',
		users: [
			{
				_id: '667a84d10ade7765010e06ec',
				status: 'joined',
				firstName: 'janvi',
				cost: '$199.99',
				lastName: 'jasoriya',
				countryCode: '+1',
				phone: '1725280011',
				email: 'janvi.jasoriya+78@satschel.com',
				role: 'Admin (Owner)',
				fundIds: [],
				developer: false,
				isAgentPermitted: false,
				business: '667a83f20ade7765010e00c9',
				linkedInUrl: '',
				isVerifiedEmail: true,
				isVerifiedPhone: true,
				invitedDate: '2024-06-25T08:50:25.802Z',
				inviterUserId: '6639d998d709041133de2d12',
				image: null,
				isLock: false,
				isDeleted: false,
				isPrimary: false,
				isBlocked: false,
				subscription: {
					usageRecordIds: ['667a8512950ea12e00c65901'],
					service: 'onboarding',
					startedAt: '2024-06-25T08:51:32.944Z',
				},
				sandboxStatus: false,
				mfaEnabled: false,
				mfaId: null,
				sandbox: false,
				createdAt: '2024-06-25T08:50:25.805Z',
				updatedAt: '2024-06-25T08:51:38.444Z',
				__v: 0,
			},
			{
				_id: '667a83f20ade7765010e00d2',
				firstName: 'janvi',
				status: 'pending',
				cost: '$101.99',
				lastName: 'jasoriya',
				countryCode: '+1',
				phone: '1725280088',
				email: 'janvi.jasoriya+119@satschel.com',
				role: 'Manager',
				fundIds: [],
				developer: false,
				isAgentPermitted: false,
				business: '667a83f20ade7765010e00c9',
				linkedInUrl: '',
				isVerifiedEmail: true,
				isVerifiedPhone: false,
				invitedDate: '2024-06-25T08:46:42.772Z',
				inviterUserId: '6639d998d709041133de2d12',
				image: null,
				isLock: false,
				isDeleted: false,
				isPrimary: true,
				isBlocked: false,
				subscription: {
					usageRecordIds: [],
					service: null,
					startedAt: null,
				},
				sandboxStatus: false,
				mfaEnabled: false,
				mfaId: null,
				sandbox: false,
				createdAt: '2024-06-25T08:46:42.774Z',
				updatedAt: '2024-06-25T08:48:13.805Z',
				__v: 0,
			},
		],
		totalUsers: 2,
	},
	{
		_id: '667969150ade776501095a08',
		name: 'janviiiii',
		parentBusinessId: '6639d998d709041133de2d0f',
		ownBilling: false,
		account: 'Accenture',
		transactions: 232,
		total_revennue: `$4343.00`,
		differentCompany: true,
		subscription: {
			id: '66796916950ea12e00c64e2d',
			managedBy: null,
			services: {
				onboarding: {
					createdAt: 1719232790854,
					cancelledAt: null,
					startedAt: 1719232790854,
					expiredAt: null,
					cancellationDetail: null,
					_id: '667969160ade776501095a21',
				},
				simpliciSign: null,
				simpliciText: null,
				_id: '667969160ade776501095a20',
			},
			_id: '667969160ade776501095a1f',
		},
		approveStatus: 'REJECTED',
		isLock: false,
		isLive: false,
		createdAt: '2024-06-24T12:39:49.987Z',
		updatedAt: '2024-06-24T12:50:52.348Z',
		users: [
			{
				_id: '6679696f0ade776501095e44',
				firstName: 'janvi',
				status: 'pending',
				cost: '$71.99',
				lastName: 'jasoriya',
				countryCode: '+91',
				phone: '7413064755',
				email: 'janvi.jasoriya+88@satschel.com',
				role: 'Manager',
				fundIds: [],
				developer: false,
				isAgentPermitted: false,
				business: '667969150ade776501095a08',
				linkedInUrl: '',
				isVerifiedEmail: false,
				isVerifiedPhone: true,
				invitedDate: '2024-06-24T12:41:19.297Z',
				inviterUserId: '6639d998d709041133de2d12',
				image: null,
				isLock: false,
				isDeleted: false,
				isPrimary: false,
				isBlocked: false,
				subscription: {
					usageRecordIds: ['667969a6950ea12e00c64e8f'],
					service: 'onboarding',
					startedAt: '2024-06-24T12:42:16.421Z',
				},
				sandboxStatus: false,
				mfaEnabled: false,
				mfaId: null,
				sandbox: false,
				createdAt: '2024-06-24T12:41:19.299Z',
				updatedAt: '2024-06-24T12:43:51.448Z',
				__v: 0,
			},
			{
				_id: '667969160ade776501095a11',
				firstName: 'janvi',
				lastName: 'jasoriya',
				cost: '$41.99',
				countryCode: '+91',
				phone: '7413064788',
				email: 'janvi.jasoriya+9999@satschel.com',
				role: 'Admin (Owner)',
				fundIds: [],
				developer: false,
				isAgentPermitted: false,
				business: '667969150ade776501095a08',
				linkedInUrl: '',
				isVerifiedEmail: true,
				isVerifiedPhone: true,
				invitedDate: '2024-06-24T12:39:50.009Z',
				inviterUserId: '6639d998d709041133de2d12',
				image: null,
				isLock: false,
				isDeleted: false,
				isPrimary: true,
				isBlocked: false,
				subscription: {
					usageRecordIds: [],
					service: null,
					startedAt: null,
				},
				sandboxStatus: false,
				mfaEnabled: false,
				mfaId: null,
				sandbox: false,
				createdAt: '2024-06-24T12:39:50.010Z',
				updatedAt: '2024-06-24T12:45:03.384Z',
				__v: 0,
			},
		],
		totalUsers: 2,
	},
	{
		_id: '667968c80ade776501095507',
		name: 'janvi99',
		parentBusinessId: '6639d998d709041133de2d0f',
		ownBilling: true,
		differentCompany: false,
		transactions: 654,
		total_revennue: '$3334.00',
		account: 'SAP',
		subscription: null,
		approveStatus: 'APPROVED',
		isLock: false,
		isLive: false,
		createdAt: '2024-06-24T12:38:32.103Z',
		updatedAt: '2024-06-24T12:38:32.103Z',
		users: [
			{
				_id: '667968c80ade776501095510',
				firstName: 'janvi',
				status: 'joined',
				cost: '$14.99',
				lastName: 'jasoriya',
				countryCode: '+91',
				phone: '7413064766',
				email: 'janvi.jasoriya+124@satschel.com',
				role: 'Manager',
				fundIds: [],
				developer: false,
				isAgentPermitted: false,
				business: '667968c80ade776501095507',
				linkedInUrl: '',
				isVerifiedEmail: false,
				isVerifiedPhone: false,
				invitedDate: '2024-06-24T12:38:32.167Z',
				inviterUserId: '6639d998d709041133de2d12',
				image: null,
				isLock: false,
				isDeleted: false,
				isPrimary: true,
				isBlocked: false,
				subscription: {
					usageRecordIds: [],
					service: null,
					startedAt: null,
				},
				sandboxStatus: false,
				mfaEnabled: false,
				mfaId: null,
				sandbox: false,
				createdAt: '2024-06-24T12:38:32.169Z',
				updatedAt: '2024-06-24T12:38:32.169Z',
				__v: 0,
			},
		],
		totalUsers: 1,
	},
	{
		_id: '66795c580ade7765010880c5',
		name: 'janvi',
		parentBusinessId: '6639d998d709041133de2d0f',
		ownBilling: true,
		transactions: 776,
		total_revennue: '$34332.00',
		differentCompany: false,
		account: 'Google',
		subscription: null,
		approveStatus: 'APPROVED',
		isLock: false,
		isLive: false,
		createdAt: '2024-06-24T11:45:28.811Z',
		updatedAt: '2024-06-24T11:45:28.811Z',
		users: [
			{
				_id: '66795c580ade7765010880ce',
				firstName: 'janvi',
				lastName: 'jasoriya',
				status: 'pending',
				cost: '$4.99',
				countryCode: '+91',
				phone: '7413064705',
				email: 'janvi.jasoriya+11@satschel.com',
				role: 'Agent',
				fundIds: [],
				developer: false,
				isAgentPermitted: false,
				business: '66795c580ade7765010880c5',
				linkedInUrl: '',
				isVerifiedEmail: true,
				isVerifiedPhone: false,
				invitedDate: '2024-06-24T11:45:28.835Z',
				inviterUserId: '6639d998d709041133de2d12',
				image: null,
				isLock: false,
				isDeleted: false,
				isPrimary: true,
				isBlocked: false,
				subscription: {
					usageRecordIds: [],
					service: null,
					startedAt: null,
				},
				sandboxStatus: false,
				mfaEnabled: false,
				mfaId: null,
				sandbox: false,
				createdAt: '2024-06-24T11:45:28.838Z',
				updatedAt: '2024-06-24T11:46:27.240Z',
				__v: 0,
			},
		],
		totalUsers: 1,
	},
];

export const SubAccountUsersHeaders = [
	{
		label: 'User Name',
		key: 'name',
		format: 'string',
		className: '',
		width: '20%',
	},
	{
		label: 'Email',
		key: 'email',
		format: 'string',
		width: '25%',
	},
	{
		label: 'Role',
		key: 'role',
		format: 'string',
		className: 'business-type',
		width: '15%',
	},
	{
		label: 'Subscription',
		key: 'subscription',
		format: 'jsx',
		width: '20%',
	},
	{
		label: 'Subscription Cost',
		key: 'cost',
		format: 'string',
		width: '5%',
	},
	{
		label: 'Status',
		key: 'status',
		format: 'jsx',
		width: '10%',
	},
	{
		label: 'Actions',
		key: 'actions',
		format: 'jsx',
		width: '5%',
	},
];

export const getStatusClassName = {
	joined: 'subaccount-joined',
	pending: 'subaccount-pending',
};
