import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue} from 'recoil';

import { API_URL, message } from 'constant';
import { envHost } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import {
	BusinessComplianceDataLoadedState,
	BusinessComplianceDataState,
} from '../states';
import { userToken } from 'states';

let sessionIdList = {};

export const useSessionApproval = () => {
	// global states
	const accessToken = useRecoilValue(userToken);
	const [businessCompliance, setBusinessCompliance] = useRecoilState(
		BusinessComplianceDataState
	);
	const [businessComplianceLoaded, setBusinessComplianceLoaded] =
		useRecoilState(BusinessComplianceDataLoadedState);

	// hooks
	const { sessionId, token,businessId } = businessCompliance ?? {};
	const { get } = useNetwork();
	const { errorNotification } = useNotification();

	const fetchSession = useCallback(async (businessId: string) => {
		setBusinessComplianceLoaded(false);
		const tokenResp = await get(`${API_URL.SIMPLICI_TOKEN}`);
		const sessionResp = await get(
			`${API_URL.BUSSINESS_COMPLIIANCE}?businessId=${businessId}`
		);
		const { sessionId,  } = sessionResp?.data?.[0]?.sessionDetails?.[0] ?? {};
		sessionIdList = sessionResp?.data?.[0]?.sessionIds;
		const { data: token } = tokenResp ?? {};
		if (token || sessionId) {
			setBusinessCompliance((prev) => ({
				...prev,
				...(token && { token }),
				...(sessionId && { sessionId }),
				...(businessId && { businessId }),
			}));
		} else {
			errorNotification(
				sessionResp?.message ?? message.SomethingWentWrongMessage
			);
		}
		setBusinessComplianceLoaded(true);
	}, []);

	const approvalSessionPage = useMemo(
		() => (
			<div
				className="ApprovalModal__session-details"
				dangerouslySetInnerHTML={{
					__html: `<session-details
				session_id=${sessionId}
				token=${token}
				comments="true"
				env=${envHost}
				type="sessions"
				compliance_data="false"
				url="/super-admin-session-details"
				session_type="complex"
				is_pdf="false"
				is_super_admin="true"
				is_show_videos="true"
				session_id_list=${JSON.stringify(sessionIdList)}
				businessId=${businessId}
				super_access_token=${accessToken}
				/>`,
				}}
			></div>
		),
		[token, sessionId, sessionIdList]
	);
	return {
		approvalSessionPage,
		fetchSession,
		businessComplianceLoaded,
		businessCompliance,
	};
};
