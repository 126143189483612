import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { REACT_APP_API_HOST as API_HOST } from 'envs';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getCookie, setCookie } from 'utils/cookies';
import { UserDetailsState } from 'views/dashboard';
import { useRecoilValue } from 'recoil';

/**
 *
 * TODO: THis is a basic setup to let the PowerBI embded report works in super admin, later this needs to be refactor to current project standard
 * @ Shabaz, @Avinash, @Gaurav
 */

export function Revenue() {
	const [embdedToken, setEmbdedToken] = useState({ token: '', report: '' });
	const userDetails = useRecoilValue(UserDetailsState);
	const [powerBIGenratedToken ,setGenratedToken] = useState('');

	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_HOST}/power-bi`,
		headers: {},
	};

	useEffect(() => {
		const generatePowerBIToken = async () => {
			const cookietoken = getCookie('GenerateToken');
			const apiUrl = 'https://api.powerbi.com/v1.0/myorg/GenerateToken';

			const roleType = userDetails.roles[0].role.name
				.toLowerCase()
				.includes('admin')
				? []
				: ['BusinessUser'];
			const businessUserRole = {
				accessLevel: 'View',
				allowSaveAs: false,
				identities: [
					{
						username: userDetails.email,
						roles: roleType,
						datasets: ['a6c9ea6f-a7a2-4dec-a190-11aaec07ad8f'],
					},
				],
			};
			const requestBody = {
				datasets: [
					{
						id: 'a6c9ea6f-a7a2-4dec-a190-11aaec07ad8f',
					},
				],
				reports: [
					{
						id: '33fb2932-7cca-445c-91c0-6e9439e0eef8',
					},
				],
			};
			// Conditionally add the businessUserRole to the requestBody if not admin
			if (roleType.length > 0) {
				Object.assign(requestBody, businessUserRole);
			}

			try {
				const response = await axios.post(apiUrl, requestBody, {
					headers: {
						Authorization: `Bearer ${cookietoken.token}`,
						'Content-Type': 'application/json',
					},
				});
				setGenratedToken(response.data.token);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(
					'Error generating token:',
					error.response ? error.response.data : error.message
				);
			}
		};

			generatePowerBIToken();

	}, []);
	const getEmbededToken = async () => {
		const cookieValue = {
			"token" : powerBIGenratedToken ,
			"report" : "33fb2932-7cca-445c-91c0-6e9439e0eef8"
		} // Retrieving the value of the cookie named "myCookie"

		if (!cookieValue) {
			const resp = await axios.request(config);

			const token = resp.data?.data?.embededToken?.data?.token;
			const reportId = resp.data?.data?.reports?.[0]?.id;

			const data = {
				token: token,
				report: reportId,
			};

			setCookie('powerBIAccessToken', data, 60);

			setEmbdedToken(data);
		} else {
			setEmbdedToken(cookieValue);
		}
	};

	useEffect(() => {	
		if(powerBIGenratedToken.length>0){
			getEmbededToken();
		}
	
	}, [powerBIGenratedToken]);

	return (
		<main className="flex min-h-screen flex-col items-center justify-between p-24">
			<PowerBIEmbed
				embedConfig={{
					type: 'report',
					id: embdedToken?.report,
					embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${embdedToken?.report}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtRS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19`,
					accessToken: embdedToken?.token,
					tokenType: models.TokenType.Embed,
					settings: {
						panes: {
							filters: {
								expanded: false,
								visible: false,
							},
						},
					},
				}}
				eventHandlers={
					new Map([
						['loaded', function () {}],
						['rendered', function () {}],
						['error', function () {}],
						['visualClicked', () => {}],
						['pageChanged', () => {}],
					])
				}
				cssClassName={'Embed-container'}
				getEmbeddedComponent={(embeddedReport) => {
					(window as any).report = embeddedReport;
				}}
			/>
		</main>
	);
}
